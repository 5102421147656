// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kategorien-js": () => import("./../../../src/pages/kategorien.js" /* webpackChunkName: "component---src-pages-kategorien-js" */),
  "component---src-pages-lp-alt-gegen-neu-js": () => import("./../../../src/pages/lp/alt-gegen-neu.js" /* webpackChunkName: "component---src-pages-lp-alt-gegen-neu-js" */),
  "component---src-pages-lp-carbon-wood-js": () => import("./../../../src/pages/lp/carbon-wood.js" /* webpackChunkName: "component---src-pages-lp-carbon-wood-js" */),
  "component---src-pages-produkte-js": () => import("./../../../src/pages/produkte.js" /* webpackChunkName: "component---src-pages-produkte-js" */),
  "component---src-pages-suche-js": () => import("./../../../src/pages/suche.js" /* webpackChunkName: "component---src-pages-suche-js" */),
  "component---src-pages-warenkorb-js": () => import("./../../../src/pages/warenkorb.js" /* webpackChunkName: "component---src-pages-warenkorb-js" */),
  "component---src-templates-default-product-js": () => import("./../../../src/templates/default-product.js" /* webpackChunkName: "component---src-templates-default-product-js" */),
  "component---src-templates-default-subcategory-js": () => import("./../../../src/templates/default-subcategory.js" /* webpackChunkName: "component---src-templates-default-subcategory-js" */),
  "component---src-templates-logo-product-js": () => import("./../../../src/templates/logo-product.js" /* webpackChunkName: "component---src-templates-logo-product-js" */),
  "component---src-templates-static-js": () => import("./../../../src/templates/static.js" /* webpackChunkName: "component---src-templates-static-js" */)
}

